import CmsForm from "components/cms/cmsForm/cmsForm";
import useIsMounted from "hooks/useIsMounted";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getFormById } from "services/form/formService";
import { contentElementBGColor, searchableID } from "utils/util";
import FormSpace from "./formSpace";
import AnimationWrapper from "components/util/animationWrapper/animationWrapper";

const Form = (props) => {
  const [form, setForm] = useState(null);
  const { cfgSelectFormId } = props.content;
  const isMounted = useIsMounted();

  const refetchFormContentElements = useSelector(
    (state) => state.cmsForm?.refetchFormContentElements || null
  );
  const editMode = useSelector((state) => state.cms.editMode);

  // fetching the actual form
  useEffect(() => {
    const formId = cfgSelectFormId;
    if (formId) {
      (async () => {
        const requestedForm = await getFormById(formId);
        if (isMounted()) {
          if (requestedForm.success) {
            setForm(requestedForm.response.data);
          } else {
            setForm(null);
          }
        }
      })();
    } else {
      setForm(null);
    }
  }, [isMounted, cfgSelectFormId]);

  // Content manager only usEffect
  // refreshing the form after updating it via forms builder editor
  useEffect(() => {
    const formId = cfgSelectFormId;
    if (formId && editMode && refetchFormContentElements !== null) {
      console.log(
        "refetching form content element because of forms builder edit..."
      );
      (async () => {
        const requestedForm = await getFormById(formId);
        if (isMounted()) {
          if (requestedForm.success) {
            setForm(requestedForm.response.data);
          } else {
            setForm(null);
          }
        }
      })();
    }
  }, [isMounted, cfgSelectFormId, editMode, refetchFormContentElements]);

  return (
    <>
      <div
        id={`anchor-${searchableID(props.content)}`}
        data-is-loading={form ? false : true}
        className={`${
          props.settings?.spaceX === 0 ? "" : "container-fluid"
        } form-container content-element ${
          props.content.cfgCustomClassName || ""
        } ${props.isLastElement ? "last-element" : ""} ${
          props.isFirstElement ? "first-element" : ""
        }`}
      >
        <Row
          id={
            props.content.cfgAnchorName
              ? `anchor-${props.content.cfgAnchorName}`
              : ""
          }
          className="g-0"
        >
          {form || editMode ? (
            <Col xs={12} className="form-body">
              <AnimationWrapper
                animationIn={props.content.cfgAnimationIn}
                animationOut={props.content.cfgAnimationOut}
              >
                <div className="form-component">
                  <CmsForm
                    form={form}
                    contentType={props.contentType}
                    cmsField={props.cmsField}
                    position={props.position}
                    headlineheading={props.settings.headlineheading}
                    headlineEnabled={props.settings.headlineEnabled}
                  />
                </div>
              </AnimationWrapper>
            </Col>
          ) : null}
        </Row>
      </div>
      <FormSpace settings={props.settings} />
      <style jsx>
        {`
          .form-container {
            background-color: ${contentElementBGColor(
              props.content.cfgBackgroundColor,
              props.settings
            )};
          }
          .form-container :global(.form-body) {
            padding: 1rem;
          }
          :global(.form-component) {
            max-width: ${props.settings?.maxWidth
              ? props.settings?.maxWidth
              : 992}px;
            margin-left: auto;
            margin-right: auto;
          }
        `}
      </style>
    </>
  );
};

export default Form;
