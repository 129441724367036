import { globalSettings } from "services/globalSettings/globalSettingsService";
import { getColorVariableName } from "utils/util";

export const buttonStyledStyle = () => {
  const settings = globalSettings.button;
  return (
    <style jsx global>{`
      .styled-button {
        // width: 100%;
        max-width: ${settings.buttonmaxwidth}px !important;
        min-width: ${settings.buttonminwidth}px !important;
        text-align: center;

        &.MuiButtonBase-root {
          background-color: var(
            ${getColorVariableName(settings.defaultButtonColor)}
          );
          border: ${settings.defaultButtonBorder !== ""
            ? settings.defaultButtonBorder
            : "none"};
          border-radius: ${settings.defaultButtonBorderRadius}px;
          font-family: inherit;
          font-weight: ${settings.buttonfontweight};
          padding: ${`${settings.buttonpaddingvertical}px ${settings.buttonpaddinghorizontal}px`};
          text-transform: none;

          font-family: inherit !important;
          color: var(${getColorVariableName(settings.defaultButtonFontColor)});
          // display: inline-block;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:hover {
            background-color: var(
              ${getColorVariableName(settings.defaultButtonColorHover)}
            );

            color: var(
              ${getColorVariableName(settings.defaultButtonFontColorHover)}
            );
          }
        }
        &.primary {
          &.MuiButtonBase-root {
            background-color: var(
              ${getColorVariableName(settings.primaryButtonColor)}
            );
            border: ${settings.primarybuttonborder !== ""
              ? settings.primarybuttonborder
              : "none"};
            border-radius: ${settings.primarybuttonborderradius}px;

            color: var(
              ${getColorVariableName(settings.primaryButtonFontColor)}
            );

            &:hover {
              background-color: var(
                ${getColorVariableName(settings.primaryButtonColorHover)}
              );

              color: var(
                ${getColorVariableName(settings.primaryButtonFontColorHover)}
              );
            }
          }
        }
        &.secondary {
          &.MuiButtonBase-root {
            background-color: var(
              ${getColorVariableName(settings.secondaryButtonColor)}
            );
            border: ${settings.secondarybuttonborder !== ""
              ? settings.secondarybuttonborder
              : "none"};
            border-radius: ${settings.secondarybuttonborderradius}px;

            color: var(
              ${getColorVariableName(settings.secondaryButtonFontColor)}
            );

            &:hover {
              background-color: var(
                ${getColorVariableName(settings.secondaryButtonColorHover)}
              );

              color: var(
                ${getColorVariableName(settings.secondaryButtonFontColorHover)}
              );
            }
          }
        }
      }
    `}</style>
  );
};

export default buttonStyledStyle;
