import { TextField } from "@mui/material";
import Captcha from "components/captcha/captcha";
import ButtonStyled from "components/util/buttonStyled/buttonStyled";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  checkCaptcha,
  isCaptchaActivated,
} from "services/captchaService/captchaService";
import {
  renderCaptchaPlaceholder,
  renderFormField,
} from "services/cms/cmsFormService";
import { getForms } from "services/cms/cmsService";
import { submitForm } from "services/form/formService";
import { editCollectionTypeField, updateEditPageCEField } from "store/actions";
import CmsCollectionTypeAutoComplete from "../cmsCollectionTypeInputs/cmsCollectionTypeAutoComplete/cmsCollectionTypeAutoComplete";
import CmsContentHeading from "../cmsContent/cmsContentHeading/cmsContentHeading";
import CmsInputLabel from "../cmsInputLabel/cmsInputLabel";
import { cmsFormStyle } from "./cmsFormStyle";

const CmsForm = (props) => {
  const { t: tCms } = useTranslation("cms");
  const { t: tPublic } = useTranslation("public");
  const style = cmsFormStyle();
  const [isSubmittable, setIsSubmittable] = useState(false);
  const [formData, setFormData] = useState([]);
  const [submitSuccessful, setSubmitSuccessful] = useState(null);
  const editView = useSelector((state) => state.cms.editView);
  const [cmsFormsSelectList, setCmsFormsSelectList] = useState([]);
  const [captchaResponse, setCaptchaResponse] = useState(null);

  const dispatch = useDispatch();

  const cmsContentHeadingSettings = {
    headlineheading: props.headlineheading,
  };

  const submit = async (e) => {
    e.preventDefault();

    const captchaMandatory = props.form.cfgWithCaptcha ? true : false;

    const validatedCaptchaResponse = checkCaptcha(captchaResponse);

    if (
      (captchaMandatory && validatedCaptchaResponse) ||
      !captchaMandatory ||
      !isCaptchaActivated()
    ) {
      // Keep for testing:
      // RequestSize:
      console.log(
        JSON.stringify({
          formId: props.form.id,
          data: formData,
          captchaResponse: validatedCaptchaResponse,
        }).length /
          1024 /
          1024
      );

      const submitRequest = await submitForm({
        formId: props.form.id,
        data: formData,
        captchaResponse: validatedCaptchaResponse,
        ...(props.form.cfgWithHP &&
          document
            .querySelector("#form-" + props.form.id)
            .elements.namedItem("websiteUrl").value !== "" && {
            hpTriggered: true,
          }),
      });

      if (submitRequest.success) {
        setSubmitSuccessful(true);
      } else {
        setSubmitSuccessful(false);
      }
      // Reset captcha response after submit
      setCaptchaResponse(null);
    } else if (captchaMandatory && !validatedCaptchaResponse) {
      setCaptchaResponse("");
    }
  };

  const updateFormData = (validatedFieldData) => {
    const previousFormData = formData;
    previousFormData[validatedFieldData.formPos] = {
      ...previousFormData[validatedFieldData.formPos],
      fieldValue: validatedFieldData.value,
      isValid: validatedFieldData.isValid,
    };
    // Note: Make sure to parse & stringify!
    setFormData(JSON.parse(JSON.stringify(previousFormData)));
  };

  const resetForm = () => {
    setIsSubmittable(false);
    setSubmitSuccessful(null);
    setCaptchaResponse(null);
    setFormData([]);
  };

  const onFormSelectChange = (value) => {
    if (value !== "") {
      if (props.contentType) {
        dispatch(
          editCollectionTypeField(
            props.contentType,
            props.cmsField,
            value,
            props.cmsPosition
          )
        );
      } else {
        dispatch(
          updateEditPageCEField(
            parseInt(props.position, 10),
            "cfgSelectFormId",
            parseInt(value, 10)
          )
        );
      }
    }
  };

  // loads all forms to fill the dropdown
  useEffect(() => {
    if (editView && props.form === null) {
      (async () => {
        const formsResult = await getForms();

        const data = formsResult.success ? formsResult.response.data : [];
        const labelArr =
          data.length > 0
            ? data.map((form) => ({ label: form.name, id: form.id }))
            : [];
        setCmsFormsSelectList(labelArr);
      })();
    }
  }, [editView, props.form]);

  // Initial Loading of FormData
  useEffect(() => {
    if (formData.length < 1) {
      if (props.form && props.form.fields.length > 0) {
        // adding isValid to initial formData
        setFormData(
          props.form.fields.map((formField) => {
            return {
              ...formField,
              isValid:
                formField.__component === "formfields.checkbox" &&
                !formField.cfgFieldIsMandatory
                  ? true
                  : false,
            };
          })
        );
      }
    }
  }, [props.form, formData]);

  // Checking validity-State of formData
  useEffect(() => {
    if (formData.length > 0) {
      const allFieldsValid = formData.every((formField) => {
        if (formField.cfgFieldIsMandatory && !formField.isValid) {
          return false;
        }
        if (formField.fieldValue && !formField.isValid) {
          return false;
        }
        return true;
      });
      // Keep this console.log for testing.
      console.log({ allFieldsValid });
      setIsSubmittable(allFieldsValid);
    }
  }, [formData]);

  return (
    <>
      <Row className="edge-to-edge-row">
        <Col xs={12} className="cms-form">
          {submitSuccessful ? (
            <div className="submit-successful">
              {props.headlineEnabled && (
                <CmsContentHeading
                  content={props.form?.title}
                  cmsPosition={props.position}
                  cmsField="title"
                  settings={cmsContentHeadingSettings}
                  textAlign="center"
                  disableEditView
                  cmsPlaceholder={tPublic("title")}
                />
              )}
              <br />
              <p>{tPublic("form-formSendingSuccessful")}</p>
              <br />
              <ButtonStyled style="primary" onClick={() => resetForm()}>
                {tPublic("back")}
              </ButtonStyled>
            </div>
          ) : (
            <>
              {editView && !props.disableEditView ? (
                <>
                  {props.form ? (
                    props.form.fields.length > 0 ? (
                      <form
                        id={"form-" + props.form.id}
                        onSubmit={(e) => submit(e)}
                      >
                        {props.headlineEnabled && (
                          <CmsContentHeading
                            content={props.form?.title}
                            cmsPosition={props.position}
                            cmsField="title"
                            settings={cmsContentHeadingSettings}
                            textAlign="center"
                            disableEditView
                            cmsPlaceholder={tPublic("title")}
                          />
                        )}
                        {props.form.fields.map((formField, index) => {
                          return (
                            <div
                              className={`form-field`}
                              key={"" + props.form.id + index}
                            >
                              {renderFormField(
                                formField,
                                index,
                                true,
                                updateFormData
                              )}
                            </div>
                          );
                        })}

                        {props.form.cfgWithCaptcha
                          ? renderCaptchaPlaceholder()
                          : null}

                        <Row className="mt-3">
                          <Col xs={12}>
                            <p>
                              <span className="mandatory-field">*</span>
                              {tPublic("form-markedFieldsAreMandatory")}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={12} style={{ textAlign: "center" }}>
                            <ButtonStyled
                              type="reset"
                              style="secondary"
                              onClick={() => setIsSubmittable(false)}
                            >
                              {tPublic("reject")}
                            </ButtonStyled>
                            <ButtonStyled
                              type="submit"
                              style="primary"
                              disabled={!isSubmittable}
                            >
                              {props.form.cfgFormSubmitButtonText ||
                                tPublic("send")}
                            </ButtonStyled>
                          </Col>
                        </Row>
                      </form>
                    ) : (
                      <>
                        {/* If the formId could not be fetched or the 
                          form has no form fields. */}
                        {props.form && props.form.fields.length === 0 ? (
                          <p className="text-center cms-info-text">
                            {tPublic("form-noFormFieldsInForm")}
                          </p>
                        ) : (
                          <p className="text-center cms-info-text">
                            {tPublic("form-couldNotLoadForm")}
                          </p>
                        )}
                      </>
                    )
                  ) : (
                    <>
                      {/* If the formId is 0. */}
                      <CmsCollectionTypeAutoComplete
                        label={tCms("form-selectForm")}
                        fullWidth
                        options={cmsFormsSelectList}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              style: {
                                color: "#fff",
                              },
                            }}
                            placeholder={tCms("form-selectForm")}
                            margin="none"
                            border="none"
                          />
                        )}
                        onChange={(e, value) => {
                          if (value) {
                            onFormSelectChange(value.id);
                          }
                        }}
                        getOptionLabel={(option) => `${option.label}`}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  {props.form ? (
                    props.form.fields.length > 0 ? (
                      <form
                        id={"form-" + props.form.id}
                        onSubmit={(e) => submit(e)}
                      >
                        {props.headlineEnabled && (
                          <CmsContentHeading
                            content={props.form?.title}
                            cmsPosition={props.position}
                            cmsField="title"
                            settings={cmsContentHeadingSettings}
                            textAlign="center"
                            disableEditView
                            cmsPlaceholder={tPublic("title")}
                          />
                        )}
                        {props.form.fields.map((formField, index) => {
                          return (
                            <div
                              className={`form-field`}
                              key={"" + props.form.id + index}
                            >
                              {renderFormField(
                                formField,
                                index,
                                false,
                                updateFormData
                              )}
                            </div>
                          );
                        })}
                        {props.form.cfgWithCaptcha ? (
                          <>
                            <Row>
                              <Col xs={12}>
                                <div className="py-2">
                                  <Captcha
                                    doneCallback={setCaptchaResponse}
                                    showHint={captchaResponse === ""}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </>
                        ) : null}

                        {props.form.cfgWithHP ? (
                          <>
                            <Row>
                              <Col xs={12}>
                                <div className="py-2 url-field-wrapper">
                                  <CmsInputLabel
                                    label={tPublic("form-websiteUrlLabel")}
                                  />
                                  <input
                                    type="text"
                                    name="websiteUrl"
                                    tabIndex="-1"
                                    autoComplete="off"
                                    aria-label={tPublic(
                                      "form-websiteUrlDescription"
                                    )}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </>
                        ) : null}

                        <Row>
                          <Col xs={12}>
                            <p>
                              <span className="mandatory-field">*</span>
                              {tPublic("form-markedFieldsAreMandatory")}
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={12} style={{ textAlign: "center" }}>
                            <ButtonStyled
                              type="reset"
                              style="secondary"
                              onClick={() => setIsSubmittable(false)}
                            >
                              {tPublic("reject")}
                            </ButtonStyled>
                            <ButtonStyled
                              type="submit"
                              style="primary"
                              disabled={!isSubmittable}
                            >
                              {props.form.cfgFormSubmitButtonText ||
                                tPublic("send")}
                            </ButtonStyled>
                          </Col>
                        </Row>
                      </form>
                    ) : (
                      <>
                        {/* If the formId could not be fetched. show nothing to the user */}
                      </>
                    )
                  ) : (
                    <>{/* If the formId is 0. */}</>
                  )}
                </>
              )}
            </>
          )}
        </Col>
      </Row>
      {/* fixed style */}
      <style jsx>{style}</style>
      {/* dynamic style */}
      <style jsx>{`
        @media (min-width: 0px) {
          :global(.captcha-placeholder img) {
            transform: scale(0.7);
            transform-origin: 0 0;
            cursor: not-allowed;
            float: left;
          }
        }

        @media (min-width: 567px) {
          :global(.captcha-placeholder img) {
            transform: scale(1);
            transform-origin: unset;
            cursor: not-allowed;
          }
        }

        :global(.url-field-wrapper) {
          position: absolute;
          left: -9999px;
        }
      `}</style>
    </>
  );
};

export default CmsForm;
