import dynamic from "next/dynamic";
import Link from "next/link";
import { getTargetValue } from "utils/util";
import ButtonStyledStyle from "./buttonStyledStyle";

const Button = dynamic(() => import("@mui/material/Button"));

/**
 * General styled Button to use for any purpose
 * 
 * @param {*} props
 * @param {String} props.type // eg. type="submit" if you want to submit a form with cmsButton 
 * @param {Function} props.onClick
 * @param {String} props.link
 * @param {String} props.style // primary || secondary || (default)
 * @returns
 * @example 
 * // Link:
 *  <ButtonStyled
      style="primary"
      link="https://example.com"
    >
    Example
    </ButtonStyled>
  
 * // Button:
 * <ButtonStyled
      style="secondary"
      type="submit"
      onClick={() => submitFunction()}
    >
    Submit
    </ButtonStyled>
 * 
 * 
 * 
 */
const ButtonStyled = ({ style, link, onClick, children, type, disabled }) => {
  const btnStyle =
    style === "primary"
      ? "primary "
      : style === "secondary"
      ? "secondary "
      : "";

  return (
    <>
      {link ? (
        <Link href={link} passHref>
          <Button
            target={getTargetValue(link)}
            className={`${btnStyle}styled-button`}
            disabled={disabled}
          >
            {children}
          </Button>
        </Link>
      ) : (
        <Button
          onClick={onClick}
          type={type || "button"}
          className={`${btnStyle}styled-button`}
          disabled={disabled}
        >
          {children}
        </Button>
      )}
      <ButtonStyledStyle />
    </>
  );
};

export default ButtonStyled;
