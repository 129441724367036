import { globalSettings } from "services/globalSettings/globalSettingsService";
import css from "styled-jsx/css";

export const cmsFormStyle = () => {
  return css`
    :global(.cms-form) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    :global(.cms-form h3) {
      text-align: center;
    }

    :global(.checkbox-container) {
      padding: 1rem 0 1rem 0;
      :global(.cms-checkbox) {
        :global(.MuiCheckbox-root) {
          margin-top: -5px !important;
          margin-left: -13px !important; 
        }
        :global(.cms-input-label) {
          padding-right: 10px;
          margin: 0px;
        }
      }
      :global(.text-with-label) {
        padding-top: 30px;
      }
      :global(.text-without-label) {
        padding-top: 9px;
        padding-left: 8px;
      }
    }

    :global(.form-field .MuiInput-input) {
      color: ${globalSettings.paragraph.paragraphfontcolor
        ? globalSettings.color[globalSettings.paragraph.paragraphfontcolor]
        : globalSettings.color.primaryfontcolor1};
    }

    /* Color neutral placeholder */
    :global(.form-field .MuiInput-input)::placeholder {
      color: ${globalSettings.paragraph.paragraphfontcolor
        ? globalSettings.color[globalSettings.paragraph.paragraphfontcolor]
        : globalSettings.color.primaryfontcolor1};
      filter: brightness(0.9);
    }

    /* Color SVGs in Paragraph-Color  */
    :global(.form-field .MuiSvgIcon-root) {
      color: ${globalSettings.paragraph.paragraphfontcolor
        ? globalSettings.color[globalSettings.paragraph.paragraphfontcolor]
        : globalSettings.color.primaryfontcolor1};
    }

    /* (Overwrite) Color SVGs in Calendar-Menu */
    :global(.form-field .MuiCalendarPicker-root .MuiSvgIcon-root) {
      color: black !important;
    }

    :global(span.mandatory-field) {
      color: red;
    }

    :global(.cms-form .styled-button) {
      margin: 15px;
    }

    :global(.cms-form .styled-button.Mui-disabled) {
      opacity: 0.25;
      cursor: not-allowed !important;
      pointer-events: auto !important;
    }

    :global(.cms-form .submit-successful) {
      margin-top: 2rem;
      margin-bottom: 2rem;
      text-align: center;
      padding: 3rem;
      word-break: break-word;
    }
  `;
};
