import { useTranslation } from "next-i18next";
import FriendlyCaptcha from "./friendlycaptcha/friendlycaptcha";
import ReCaptcha from "./recaptcha/reCaptcha";

/**
 * Used to display a captcha.
 * Captcha type is set in .env NEXT_PUBLIC_CAPTCHA_TYPE
 * @param {function} props.doneCallback useState setter (used by friendlycaptcha to set the solution)
 * @param {boolean} props.showHint show info text true or false
 * @returns
 */
export default function Captcha(props) {
  const { t: tPublic } = useTranslation("public");

  const renderCaptcha = () => {
    switch (process.env.NEXT_PUBLIC_CAPTCHA_TYPE) {
      case "friendlycaptcha":
        return (
          <FriendlyCaptcha
            doneCallback={props.doneCallback}
            hintText={tPublic("fillOutCaptcha")}
            showHint={props.showHint}
            hintTextclassName="captcha-hint"
          />
        );
      case "recaptcha":
        return (
          <ReCaptcha
            hintText={tPublic("fillOutCaptcha")}
            showHint={props.showHint}
            hintTextclassName="captcha-hint"
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      {renderCaptcha()}
      <style jsx>{`
        :global(.captcha-hint) {
          color: red;
        }
      `}</style>
    </>
  );
}
