import { getNextJsApiURL } from "utils/api";
import { axiosGetRequest, axiosPostRequest } from "utils/clientUtil";
import { createToast, translate } from "utils/util";

export const getFormById = async (formId) => {
  const result = await axiosGetRequest(getNextJsApiURL(`/forms/${formId}`));
  if (result.success) {
  } else {
    console.log(translate("cms:formFetchError"));
    // createToast({ type: "error", msg: translate("formFetchError") });
  }
  return result;
};

export const submitForm = async (data) => {
  const result = await axiosPostRequest(
    getNextJsApiURL(`/forms/submit-form`),
    data,
    null,
    { headers: { "Content-Type": "application/json" } }
  );
  if (result.success) {
    if (result.response.data) {
      createToast({
        type: "success",
        msg: translate("public:formSubmitSuccess"),
      });
    } else {
      createToast({
        type: "error",
        msg: translate("public:formValidationError"),
      });
    }
  } else {
    createToast({ type: "error", msg: translate("public:formSubmitError") });
  }
  return result;
};

/**
 * Please don't add more validations.
 * Because you would need to re-test every formField and validation that uses this method.
 * You can for example add a new method with your own validation.
 *
 * Checks given value string against all selected checks.
 * Make sure to always sync validation-Changes with backend!
 * @param {String} value the value to check
 * @param {Boolean} checkMandatory is input field mandatory
 * @param {Boolean} checkNoSpecialCharacters
 * @param {Boolean} checkNoLetters
 * @param {Boolean} checkNoNumbers
 * @param {Number} checkMaxChars
 * @param {Number} checkMinChars
 * @param {String} checkCustomRegex regex string must be without beginning and ending escape slash e.g. "/^[5]+$/" => "^[5]+$"
 * @param {Boolean} checkEmail
 * @param {Boolean} checkCheckbox
 * @param {Boolean} checkNoWhitespaceCharacters
 * @returns true if all selected checks are fullfilled, else false
 */
export const validateFormInput = (
  value,
  checkMandatory,
  checkNoSpecialCharacters,
  checkNoLetters,
  checkNoNumbers,
  checkMaxChars,
  checkMinChars,
  checkCustomRegex,
  checkEmail,
  checkCheckbox,
  checkNoWhitespaceCharacters
) => {
  // mandatory check
  if (checkMandatory) {
    if (value === null || value.length < 1) {
      return false;
    }
  }
  // no letters
  if (checkNoLetters) {
    // Make sure to always sync validation-Changes with backend!
    if (value && /[a-zA-ZÄÖÜäöüß]/g.test(value.trim())) {
      return false;
    }
  }
  // no special characters except whitespace
  if (checkNoSpecialCharacters) {
    // Make sure to always sync validation-Changes with backend!
    if (value && /^[a-z A-ZÄÖÜäöüß\d]+$/.test(value.trim()) === false) {
      return false;
    }
  }
  // no whitespace (no .trim() is applied)
  if (checkNoWhitespaceCharacters) {
    // Make sure to always sync validation-Changes with backend!
    if (value && /^\S*$/.test(value) === false) {
      return false;
    }
  }
  // only numbers
  if (checkNoNumbers) {
    // Make sure to always sync validation-Changes with backend!
    if (value && /\d/g.test(value.trim())) {
      return false;
    }
  }
  // max chars
  if (checkMaxChars) {
    if (value && value.length > checkMaxChars) {
      return false;
    }
  }
  // min chars
  if (checkMinChars) {
    if (value && value.length < checkMinChars) {
      return false;
    }
  }
  // custom regex
  if (value && checkCustomRegex) {
    const customRegEx = RegExp(checkCustomRegex);
    if (customRegEx.test(value) === false) {
      return false;
    }
  }

  // email
  if (value && checkEmail) {
    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regex.test(value) === false) {
      return false;
    }
  }
  // checkbox
  if (checkCheckbox) {
    if (checkMandatory && !value) {
      return false;
    }
  }
  return true;
};

export const valiedateFormFileInput = (
  file,
  checkMandatory,
  cfgFieldAllowedFileEndings,
  maxFormFileSize
) => {
  const formField = {
    isValid: true,
    value: null,
  };
  const warnings = [];

  if (checkMandatory) {
    if (file === null) {
      formField.isValid = false;
    }
  }

  if (file) {
    // file exists even if not-mandatory
    // fileSize-Check
    if (file.size > (maxFormFileSize + 0.01) * 1024 * 1024) {
      formField.isValid = false;
      warnings.push(
        translate("cms:fileNeedsToBeSmallerThan", {
          maxFileSize: maxFormFileSize,
        })
      );
    }

    // fileExtension-Check: Removing whitespaces in cfgFieldAllowedFileEndings
    const allowedFileEndings = cfgFieldAllowedFileEndings.replace(/\s/g, "");
    if (allowedFileEndings.length > 0 && file.name) {
      const allowedFileEndingsArray = allowedFileEndings
        .split(",")
        .filter((e) => e !== "");
      if (allowedFileEndingsArray.length > 0) {
        // Check for matching of name.jpg with ['.jpg', '.pdf']
        if (
          !allowedFileEndingsArray.includes(
            file.name.substring(file.name.lastIndexOf("."))
          )
        ) {
          // accepted Size & accepted fileEnding
          formField.isValid = false;
          warnings.push(
            translate("cms:invalidFileFormat", {
              allowedFileEndings: allowedFileEndings,
            })
          );
        }
      }
    } else {
      // No allowedFileEndings provided!
      formField.isValid = false;
      warnings.push(
        translate("cms:invalidFileFormat", {
          allowedFileEndings: allowedFileEndings,
        })
      );
      console.log("No allowedFileEndings provided!");
    }
  }
  return { formField, warnings };
};
