import dynamic from "next/dynamic";
import useWindowSize from "hooks/useWindowSize";
import { globalSettings } from "services/globalSettings/globalSettingsService";
import { useSelector } from "react-redux";

const Animation = dynamic(() => import("./animation"));

const AnimationWrapper = (props) => {
  const { width } = useWindowSize();
  const editView = useSelector((state) => state.cms.editView);

  const animationsActive =
    width > globalSettings.responsive.breakpointmobile ||
    (globalSettings.animation?.animationsMobile &&
      width < globalSettings.responsive.breakpointmobile);

  return (
    <>
      {!editView &&
      props.animationIn &&
      props.animationIn !== "none" &&
      animationsActive ? (
        <Animation
          animationIn={props.animationIn}
          animationOut={props.animationOut}
        >
          {props.children}
        </Animation>
      ) : (
        <div> {props.children} </div>
      )}
    </>
  );
};

export default AnimationWrapper;
