import dynamic from "next/dynamic";
import CmsInputLabel from "components/cms/cmsInputLabel/cmsInputLabel";

const Autocomplete = dynamic(() => import("@mui/material/Autocomplete"));

const TextField = dynamic(() => import("@mui/material/TextField"));

/**
 * collection type inputs
 * Note: defaultValue = value
 * @param {*} props
 * @returns
 */
const CmsCollectionTypeAutoComplete = (props) => {
  return (
    <>
      <CmsInputLabel label={props.label} mandatory={props.mandatory} />
      <Autocomplete
        freeSolo={props.freeSolo}
        id={props.id ? "cms-autocomplete-id" + props.id : "cms-autocomplete-id"}
        className={`${props.className ? `${props.className}` : ``} ${
          props.multiple ? "cms-autocomplete-multiple" : ""
        }`}
        style={{ width: props.fullWidth ? "100%" : "190px" }}
        multiple={props.multiple}
        // Preventing starting as uncontrolled
        value={props.defaultValue || null}
        options={props.options}
        groupBy={props.groupBy ? props.groupBy : null}
        onChange={(e, v) => props.onChange(e, v)}
        sx={props.sx ? props.sx : null}
        getOptionLabel={(option) =>
          props.getOptionLabel ? props.getOptionLabel(option) : option.name
        }
        filterSelectedOptions={props.filterSelectedOptions}
        renderOption={props.renderOption ? props.renderOption : null}
        size="small"
        aria-owns={props.id ? props.id + "-owns" : ""}
        disableClearable={
          props.disableClearable ? props.disableClearable : false
        }
        disablePortal
        disabled={props.disabled ? props.disabled : false}
        ListboxProps={
          props.listboxProps
            ? {
                ...props.listboxProps,
                className: `${
                  props.listboxProps.className
                    ? props.listboxProps.className + " MuiAutocomplete-listbox "
                    : "MuiAutocomplete-listbox"
                }`,
              }
            : { className: "MuiAutocomplete-listbox" }
        }
        renderInput={
          props.renderInput
            ? props.renderInput
            : (params) => (
                <TextField
                  // variant="standard"
                  variant="outlined"
                  {...params}
                  InputLabelProps={{
                    style: { color: "white" },
                  }}
                  margin="none"
                />
              )
        }
      />
    </>
  );
};

export default CmsCollectionTypeAutoComplete;
