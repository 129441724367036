import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { globalSettings } from "services/globalSettings/globalSettingsService";
import { editCollectionTypeField, updateEditPageCEField } from "store/actions";
// NOTE: import contentmanager only components dynamic
const CmsMaxLengthIndicator = dynamic(() =>
  import(
    "components/cms/contentManagerControls/cmsMaxLengthIndicator/cmsMaxLengthIndicator"
  )
);

/**
 * Component for updating/displaying (editmode/editview) values
 * from the CMS page.content array
 * Do not use this for other purposes
 *
 * @param {Object} props
 * @param {*} props.settings
 * @param {*} props.maxLength
 * @param {*} props.contentType
 * @param {*} props.cmsField
 * @param {*} props.cmsPosition
 * @param {*} props.disableEditView
 * @param {string} props.content
 * @param {*} props.cmsPlaceholder
 * @param {*} props.maxLengthIndicatorMargin
 * @param {boolean} props.hideFromHtmlDom If true no (possible SEO-relevant) HTML-Element is displayed.
 * @param {string} props.highlightedText
 * @param {boolean} props.hasAMStyle
 * @returns
 */
const CmsContentHeading = (props) => {
  const { t: tCms } = useTranslation("cms");
  const editView = useSelector((state) => state.cms.editView);
  const dispatch = useDispatch();

  const HeadingTag =
    props.settings &&
    props.settings?.headlineheading &&
    props.settings.headlineheading !== "default"
      ? props.settings.headlineheading === "paragraph"
        ? "p"
        : props.settings.headlineheading
      : "h1";

  const settingsHeadingTag =
    props.settings &&
    props.settings?.headlineheading &&
    props.settings.headlineheading !== "default"
      ? props.settings.headlineheading
      : "h1";

  const headingStyleTag =
    props.settings &&
    props.settings?.headlineheading &&
    props.settings.headlineheading !== "default"
      ? props.settings.headlineHeadingStyle
      : "h1";

  const settingHeadingStyleTag =
    props.settings &&
    props.settings?.headlineHeadingStyle &&
    props.settings.headlineHeadingStyle !== "default"
      ? props.settings.headlineHeadingStyle
      : HeadingTag
      ? HeadingTag
      : "h1";

  // CmsContentHeading is a 'string' (<255) or 'text' (>255) field.
  // In most use-cases: 250 characters should be enough.
  const maxLength = props.maxLength || 250;
  const inputWithMaxLength = useRef(null);

  const handleChange = (e) => {
    e.target.value = e.target.value.replace(/(\r\n|\n|\r)/gm, "");

    if (props.contentType) {
      dispatch(
        editCollectionTypeField(
          props.contentType,
          props.cmsField,
          e.target.value,
          props.cmsPosition
        )
      );
    } else {
      // default case no props.contentType given -> Contentpage content array
      dispatch(
        updateEditPageCEField(props.cmsPosition, props.cmsField, e.target.value)
      );
    }
  };
  
  return (
    <>
      {editView && !props.disableEditView ? (
        <div
          className={`cms-content-heading with-cms-${HeadingTag} ${
            headingStyleTag ? `${headingStyleTag}-style` : ""
          }`}
        >
          {/* Note: There's no cms-content-heading-Style in Live-View due to cleaner DOM. tbd.*/}
          <div
            className="grow-wrap heading"
            data-replicated-value={props.content}
          >
            <textarea
              className={`cms-content-heading heading cms-${HeadingTag} ${
                headingStyleTag ? `${headingStyleTag}-style` : ""
              }`}
              type="text"
              defaultValue={props.content}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              }}
              maxLength={`${maxLength}`}
              ref={inputWithMaxLength}
              placeholder={
                !props.content
                  ? `${
                      props.cmsPlaceholder
                        ? props.cmsPlaceholder
                        : tCms("headline")
                    } <${HeadingTag}>`
                  : null
              }
              rows="1"
              onInput={(e) => {
                e.target.parentNode.dataset.replicatedValue = e.target.value;
              }}
              onChange={handleChange}
            />
            <CmsMaxLengthIndicator
              margin={
                props.maxLengthIndicatorMargin
                  ? props.maxLengthIndicatorMargin
                  : null
              }
              length={inputWithMaxLength.current?.value.length}
              maxLength={maxLength}
            />
          </div>
        </div>
      ) : props.content && !props.hideFromHtmlDom ? (
        <HeadingTag
          className={`heading ${
            headingStyleTag ? `${headingStyleTag}-style` : ""
          }`}
        >
          {props.content}
          {props.hasAMStyle ? (
            <span className="highlight">&nbsp;{props.highlightedText}</span>
          ) : null}
        </HeadingTag>
      ) : null}
      {/* dynamic style */}
      <style jsx>{`
        .heading {
          hyphens: auto;
          @media (min-width: ${globalSettings.responsive.breakpointdesktop}px) {
            word-break: break-word;
            hyphens: none;
          }
          ${props.settings?.headlineColor
            ? `color: ${props.settings.headlineColor};`
            : ""}
          ${props.textAlign ? `text-align: ${props.textAlign};` : ""}
            
          &.grow-wrap {
            font-size: ${globalSettings[
              settingsHeadingTag === "p" ? "paragraph" : settingsHeadingTag
            ][
              `${
                settingsHeadingTag === "p" ? "paragraph" : settingsHeadingTag
              }fontsizemobile`
            ]};
            font-weight: ${globalSettings[
              settingsHeadingTag === "p" ? "paragraph" : settingsHeadingTag
            ][
              `${
                settingsHeadingTag === "p" ? "paragraph" : settingsHeadingTag
              }fontweight`
            ]};
            @media (min-width: ${globalSettings.responsive
                .breakpointdesktop}px) {
              font-size: ${globalSettings[
                settingsHeadingTag === "p" ? "paragraph" : settingsHeadingTag
              ][
                `${
                  settingsHeadingTag === "p" ? "paragraph" : settingsHeadingTag
                }fontsizedesktop`
              ]};
            }
          }

          /* Note: In this component .heading exists twice! */
          &.grow-wrap {
            position: relative;

            &::after {
              font-size: ${globalSettings[
                settingHeadingStyleTag === "p"
                  ? "paragraph"
                  : settingHeadingStyleTag
              ][
                `${
                  settingHeadingStyleTag === "p"
                    ? "paragraph"
                    : settingHeadingStyleTag
                }fontsizemobile`
              ]};

              @media (min-width: ${globalSettings.responsive
                  .breakpointdesktop}px) {
                font-size: ${globalSettings[
                  settingHeadingStyleTag === "p"
                    ? "paragraph"
                    : settingHeadingStyleTag
                ][
                  `${
                    settingHeadingStyleTag === "p"
                      ? "paragraph"
                      : settingHeadingStyleTag
                  }fontsizedesktop`
                ]};
              }
            }
          }
        }
        // explanation:
        // we create a linear-gradient background image
        // we rotate the image 66.666 degrees to get the slanting optic
        // we define the color areas appearing in the desired order: yellow | nothing | grey | nothing | grey
        .highlight {
          --width: 2em;
          --part: 5.26; // change this to change the basic width for every element
          background-color: #f2c500;
          display: inline-block;
          margin-right: var(--width);
          position: relative;
        }

        .highlight:after {
          content: "";
          position: absolute;
          inset: 0 calc(-1 * var(--width)) 0 100%;
          background-image: linear-gradient(
            66.666deg,
            #f2c500 calc(var(--part) * 5.8%),
            transparent calc(var(--part) * 6%),
            transparent calc(var(--part) * 7%),
            #637984 calc(var(--part) * 7.2%),
            #637984 calc(var(--part) * 9.8%),
            transparent calc(var(--part) * 10%),
            transparent calc(var(--part) * 11%),
            #637984 calc(var(--part) * 11.2%),
            #637984 calc(var(--part) * 13.8%),
            transparent calc(var(--part) * 14%)
          );
        }
      `}</style>
    </>
  );
};

export default CmsContentHeading;
