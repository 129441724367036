/**
 * cms input label styled
 * 
 * @param {string} props.label label text
 * @param {string} props.className label text
 * @param {boolean} props.mandatory mandatory symbol next to the label text
 * @param {boolean} props.lineBreak forces a lineBreak
 * @returns
 */
const CmsInputLabel = (props) => {
  return (
    <>
      {props.label ? (
        <>
          <label className={`cms-input-label ${props.className || ""}`}>
            {props.label}
            {props.mandatory ? (
              <span className="cms-input-mandatory">*</span>
            ) : null}
          </label>
          {props.lineBreak ? <br /> : null}
        </>
      ) : null}
    </>
  );
};

export default CmsInputLabel;
